<template>
<div>
    <v-btn text icon @click="diaAnterior" :disabled="loading">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                    mdi-chevron-left
                </v-icon>
            </template>
            <span>Día anterior</span>
        </v-tooltip>
    </v-btn>

    <v-menu
        ref="menuFecha"
        v-model="menuFecha"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-btn
                icon
                v-on="on"
            >
                <v-icon>mdi-calendar-month</v-icon>
            </v-btn>
        </template>
            <v-date-picker
                v-model="pickerFecha"
                no-title
                reactive
                show-current
                :max="(!futuro)?estaFechaGral:''"
                locale="es-MX"
                @change="seleccionaFecha"
            >
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="pickerFecha = new Date().toISOString().substring(0,10)">Hoy</v-btn>

        </v-date-picker>
    </v-menu>

    <v-btn text icon @click="diaSiguiente" :disabled="loading">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                    mdi-chevron-right
                </v-icon>
            </template>
            <span>Día siguiente</span>
        </v-tooltip>
    </v-btn>
    </div>
</template>

<script>
export default {
    mounted(){
        this.pickerFecha=this.estaFechaGral
        this.estaFecha=this.moment(this.estaFechaGral).format('DD-MM-YYYY')
    },
    props:{
        fecha:String,
        futuro:Boolean,
    },
    data:()=>({
        loading:false,
        menuFecha:false,
        pickerFecha:'',

    }),

    methods:{
        diaAnterior(){
            this.estaFecha=this.moment(this.pickerFecha).subtract(1, "days").format('DD-MM-YYYY')
            this.pickerFecha=this.moment(this.pickerFecha).subtract(1, "days").format('YYYY-MM-DD')
        },
        diaSiguiente(){
            if (!this.futuro && this.pickerFecha == this.estaFechaGral){
                return false
            }
            this.estaFecha=this.moment(this.pickerFecha).add(1, "days").format('DD-MM-YYYY')
            this.pickerFecha=this.moment(this.pickerFecha).add(1, "days").format('YYYY-MM-DD')
        },
        seleccionaFecha(val){
            this.estaFecha = this.moment(val).format('DD-MM-YYYY')
            this.menuFecha=false
        },
    },
    computed:{
        estaFecha:{
            get(){return this.fecha},
            set(val){
                this.$emit('update:fecha', val)
            }
        },
        estaFechaGral(){
            return this.$store.getters['security/estaFechaMax']
        }
    },
    watch:{

    }
}
</script>

<style>

</style>